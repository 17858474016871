<template>
  <div class="bankWraps">
    <div class="facilityBox">
      <!-- 搜索框 -->
      <el-form ref="searchForm" inline :model="searchForm" size="medium">
        <el-form-item label="姓名" prop="name">
          <el-input
            v-model="searchForm.name"
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="idcard">
          <el-input
            v-model="searchForm.idcard"
            placeholder="请输入身份证号"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input
            v-model="searchForm.phone"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业名称" prop="companyName">
          <el-input
            v-model="searchForm.companyName"
            placeholder="请输入企业名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业认证状态" prop="authStatus">
          <el-select
            v-model="searchForm.authStatus"
            placeholder="请选择企业认证状态"
          >
            <el-option label="成功" value="Yes"></el-option>
            <el-option label="失败" value="No"></el-option>
            <el-option label="处理中" value="Processing"></el-option>
          </el-select>
        </el-form-item>
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-search"
          @click="search"
          >搜索</el-button
        >
        <el-button
          type="primary"
          size="medium"
          @click="$refs['searchForm'].resetFields()"
          icon="el-icon-delete"
          >清除
        </el-button>
      </el-form>
      <el-tabs v-model="activePage" @tab-click="handleClick">
        <!-- 服务商 -->
        <el-tab-pane label="服务商" name="3">
          <el-table
            :data="tableData"
            :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
            v-loading="loading"
          >
            <el-table-column
              type="index"
              align="center"
              label="序号"
              width="50"
              fixed
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="AccountId"
              align="center"
              label="银行/第三方支付会员号"
              fixed
              width="200"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="AccountName"
              align="center"
              label="虚拟支付账户"
              width="200"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="Balance"
              align="center"
              label="裕民银行账户余额"
              width="200"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span>￥{{ scope.row.Balance }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="LegalName"
              align="center"
              label="法人姓名"
              width="80"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="LegalIdCard"
              align="center"
              label="法人身份证号"
              width="160"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="RegisterPhone"
              align="center"
              label="注册手机号"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="Phone"
              align="center"
              label="预留手机号"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="CompanyName"
              align="center"
              label="企业名称"
              width="200"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="SocialCode"
              align="center"
              label="统一社会信用代码"
              width="200"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="Address"
              align="center"
              label="营业执照住所"
              width="200"
              show-overflow-tooltip
            >
            </el-table-column>
            <!--status 0 是处理中， 1是成功，2失败-->
            <el-table-column
              prop="Status"
              align="center"
              label="企业认证状态"
              width="100"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-tag
                  :type="
                    scope.row.Status == 0
                      ? 'warning'
                      : scope.row.Status == 1
                      ? 'success'
                      : 'danger'
                  "
                >
                  {{
                    scope.row.Status == 0
                      ? "处理中"
                      : scope.row.Status == 1
                      ? "成功"
                      : "失败"
                  }}
                </el-tag>
              </template>
            </el-table-column>
            <!-- AuthFlag 1为已激活，其余都是未激活 -->
            <el-table-column
              prop="Status"
              align="center"
              label="激活状态"
              width="100"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-tag :type="scope.row.AuthFlag == 1 ? 'success' : 'danger'">
                  {{ scope.row.AuthFlag == 1 ? "已激活" : "未激活" }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="BankCardNo"
              align="center"
              label="账户号码"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="BankName"
              align="center"
              label="开户银行"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="AccountDate"
              align="center"
              label="虚拟支付账户注册时间"
              width="200"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="BankCardCount"
              align="center"
              label="绑定银行卡数"
              width="100"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="Remark"
              align="center"
              label="备注说明"
              fixed="right"
              width="250"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="center"
              label="操作"
              fixed="right"
              width="260"
            >
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  @click="toFundsTransfer(scope.row)"
                  style="margin-right: 5px"
                  v-if="scope.row.CanTransfer"
                  >资金转账</el-link
                >
                <el-link
                  type="primary"
                  @click="financeDetail(scope.row)"
                  style="margin-right: 5px"
                  >资金明细</el-link
                >
                <el-link
                  type="primary"
                  @click="recordClick(scope.row)"
                  :disabled="!scope.row.IsUpdate"
                  style="margin-right: 5px"
                  >变更记录</el-link
                >
                <!-- <el-link type="warning" @click="toPushFile(scope.row.UserId)">推送文件</el-link> -->
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <!-- 货主 -->
        <el-tab-pane label="货主" name="2">
          <el-table
            :data="tableData"
            :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
            v-loading="loading"
          >
            <el-table-column
              type="index"
              align="center"
              label="序号"
              width="50"
              fixed
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="AccountId"
              align="center"
              label="银行/第三方支付会员号"
              fixed
              width="200"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="AccountName"
              align="center"
              label="虚拟支付账户"
              width="200"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="Balance"
              align="center"
              label="裕民银行账户余额"
              width="200"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span>￥{{ scope.row.Balance }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="LegalName"
              align="center"
              label="法人姓名"
              width="80"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="LegalIdCard"
              align="center"
              label="法人身份证号"
              width="160"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="RegisterPhone"
              align="center"
              label="注册手机号"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="Phone"
              align="center"
              label="预留手机号"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="CompanyName"
              align="center"
              label="企业名称"
              width="200"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="SocialCode"
              align="center"
              label="统一社会信用代码"
              width="200"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="Address"
              align="center"
              label="营业执照住所"
              width="200"
              show-overflow-tooltip
            >
            </el-table-column>
            <!--status 0 是处理中， 1是成功，2失败-->
            <el-table-column
              prop="Status"
              align="center"
              label="企业认证状态"
              width="100"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-tag
                  :type="
                    scope.row.Status == 0
                      ? 'warning'
                      : scope.row.Status == 1
                      ? 'success'
                      : 'danger'
                  "
                >
                  {{
                    scope.row.Status == 0
                      ? "处理中"
                      : scope.row.Status == 1
                      ? "成功"
                      : "失败"
                  }}
                </el-tag>
              </template>
            </el-table-column>
            <!-- AuthFlag 1为已激活，其余都是未激活 -->
            <el-table-column
              prop="Status"
              align="center"
              label="激活状态"
              width="100"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-tag :type="scope.row.AuthFlag == 1 ? 'success' : 'danger'">
                  {{ scope.row.AuthFlag == 1 ? "已激活" : "未激活" }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="BankCardNo"
              align="center"
              label="账户号码"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="BankName"
              align="center"
              label="开户银行"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="AccountDate"
              align="center"
              label="虚拟支付账户注册时间"
              width="200"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="BankCardCount"
              align="center"
              label="绑定银行卡数"
              width="100"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="Remark"
              align="center"
              label="备注说明"
              fixed="right"
              width="250"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="center"
              label="操作"
              fixed="right"
              width="260"
            >
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  @click="toFundsTransfer(scope.row)"
                  style="margin-right: 5px"
                  v-if="scope.row.CanTransfer"
                  >资金转账</el-link
                >
                <el-link
                  type="primary"
                  @click="financeDetail(scope.row)"
                  style="margin-right: 5px"
                  >资金明细</el-link
                >
                <el-link
                  type="primary"
                  @click="recordClick(scope.row)"
                  :disabled="!scope.row.IsUpdate"
                  style="margin-right: 5px"
                  >变更记录</el-link
                >
                <!-- <el-link type="warning" @click="toPushFile(scope.row.UserId)">推送文件</el-link> -->
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <!-- 司机 -->
        <el-tab-pane label="司机" name="5">
          <el-table
            :data="tableData"
            :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
            v-loading="loading"
          >
            <el-table-column
              type="index"
              align="center"
              label="序号"
              width="50"
              fixed
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="AccountId"
              align="center"
              label="银行/第三方支付会员号"
              fixed
              width="200"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="AccountName"
              align="center"
              label="虚拟支付账户"
              width="130"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="Balance"
              align="center"
              label="裕民银行账户余额"
              width="200"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span>￥{{ scope.row.Balance }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="LegalName"
              align="center"
              label="姓名"
              width="100"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="LegalIdCard"
              align="center"
              label="身份证号"
              width="160"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="RegisterPhone"
              align="center"
              label="注册手机号"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="Phone"
              align="center"
              label="预留手机号"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="BankCardNo"
              align="center"
              label="账户号码"
              width="180"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="BankName"
              align="center"
              label="开户银行"
              width="180"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="AccountDate"
              align="center"
              label="虚拟支付账户注册时间"
              width="200"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="Status"
              align="center"
              label="个人认证状态"
              width="120"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-tag
                  :type="
                    scope.row.Status == 0
                      ? 'warning'
                      : scope.row.Status == 1
                      ? 'success'
                      : 'danger'
                  "
                >
                  {{
                    scope.row.Status == 0
                      ? "处理中"
                      : scope.row.Status == 1
                      ? "成功"
                      : "失败"
                  }}
                </el-tag>
              </template>
            </el-table-column>
            <!-- AuthFlag 1为已激活，其余都是未激活 -->
            <el-table-column
              prop="Status"
              align="center"
              label="激活状态"
              width="100"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-tag :type="scope.row.AuthFlag == 1 ? 'success' : 'danger'">
                  {{ scope.row.AuthFlag == 1 ? "已激活" : "未激活" }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="BankCardCount"
              align="center"
              label="绑定银行卡数"
              width="100"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="Remark"
              align="center"
              label="备注说明"
              fixed="right"
              width="250"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="center"
              label="操作"
              fixed="right"
              width="180"
            >
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  @click="toFundsTransfer(scope.row)"
                  style="margin-right: 5px"
                  v-if="scope.row.CanTransfer"
                  >资金转账</el-link
                >
                <el-link type="primary" @click="financeDetail(scope.row)"
                  >资金明细
                </el-link>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <!-- 代理商 -->
        <el-tab-pane label="代理商" name="4">
          <el-table
            :data="tableData"
            :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
            v-loading="loading"
          >
            <el-table-column
              type="index"
              align="center"
              label="序号"
              width="50"
              fixed
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="AccountId"
              align="center"
              label="银行/第三方支付会员号"
              fixed
              width="200"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="AccountName"
              align="center"
              label="虚拟支付账户"
              width="130"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="Balance"
              align="center"
              label="裕民银行账户余额"
              width="200"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span>￥{{ scope.row.Balance }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="LegalName"
              align="center"
              label="姓名"
              width="100"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="LegalIdCard"
              align="center"
              label="身份证号"
              width="160"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="RegisterPhone"
              align="center"
              label="注册手机号"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="Phone"
              align="center"
              label="预留手机号"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="CompanyName"
              align="center"
              label="企业名称"
              width="180"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="SocialCode"
              align="center"
              label="统一社会信用代码"
              width="180"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="Address"
              align="center"
              label="营业执照住所"
              width="200"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="Status"
              align="center"
              label="企业认证状态"
              width="120"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-tag
                  :type="
                    scope.row.Status == 0
                      ? 'warning'
                      : scope.row.Status == 1
                      ? 'success'
                      : 'danger'
                  "
                >
                  {{
                    scope.row.Status == 0
                      ? "处理中"
                      : scope.row.Status == 1
                      ? "成功"
                      : "失败"
                  }}
                </el-tag>
              </template>
            </el-table-column>
            <!-- AuthFlag 1为已激活，其余都是未激活 -->
            <el-table-column
              prop="Status"
              align="center"
              label="激活状态"
              width="100"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-tag :type="scope.row.AuthFlag == 1 ? 'success' : 'danger'">
                  {{ scope.row.AuthFlag == 1 ? "已激活" : "未激活" }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="BankCardNo"
              align="center"
              label="账户号码"
              width="100"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="BankName"
              align="center"
              label="开户银行"
              width="100"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="AccountDate"
              align="center"
              label="虚拟支付账户注册时间"
              width="160"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="BankCardCount"
              align="center"
              label="绑定银行卡数"
              width="100"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="Remark"
              align="center"
              label="备注说明"
              fixed="right"
              width="250"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="center"
              label="操作"
              fixed="right"
              width="230"
            >
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  @click="toFundsTransfer(scope.row)"
                  style="margin-right: 5px"
                  v-if="scope.row.CanTransfer"
                  >资金转账</el-link
                >
                <el-link
                  type="primary"
                  @click="financeDetail(scope.row)"
                  style="margin-right: 5px"
                  >资金明细</el-link
                >
                <!-- <el-link type="warning" @click="toPushFile(scope.row.UserId)">推送文件</el-link> -->
                <el-link
                  type="primary"
                  @click="recordClick(scope.row)"
                  :disabled="!scope.row.IsUpdate"
                  style="margin-right: 5px"
                  >变更记录</el-link
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <div style="margin-top: 10px" v-if="activePage != '6'">
        <!-- 分页 -->
        <el-pagination
          background
          class="pagination"
          @current-change="handleCurrentChange"
          :current-page.sync="pagination.page"
          :page-size="pagination.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 绑定银行卡 -->
    <el-dialog
      class="recordDialog"
      :visible.sync="flag.isRecord"
      width="700px"
      :show-close="true"
    >
      <span slot="title" class="dialog-title">
        {{ firmData.CompanyName }}的变更记录
      </span>
      <el-table
        :data="recordData"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        v-loading="recordLoading"
      >
        <el-table-column
          type="index"
          align="center"
          label="序号"
          width="50"
        ></el-table-column>
        <el-table-column
          prop="Date"
          align="center"
          label="变更时间"
          width="180"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="SubmitFlag"
          align="center"
          label="提交状态"
          width="100"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="Result"
          align="center"
          label="变更结果"
          show-overflow-tooltip
        ></el-table-column>
      </el-table>
      <div style="margin-top: 10px">
        <!-- 分页 -->
        <el-pagination
          background
          class="pagination"
          @current-change="recordCurrentChange"
          :current-page.sync="recordPagination.page"
          :page-size="recordPagination.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="recordPagination.total"
        >
        </el-pagination>
      </div>
    </el-dialog>
    <!-- 推送文件类型选择 -->
    <el-dialog
      width="550px"
      title="选择推送文件类型"
      :visible.sync="pushFileDialog"
      append-to-body
    >
      <div style="display: flex; align-items: center; flex-wrap: wrap">
        <el-radio
          v-model="pushFileType"
          v-for="(item, index) in pushFileTypeList"
          :key="index"
          :label="item.id"
          style="margin-bottom: 10px"
          >{{ item.type }}</el-radio
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="pushFileDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitPushFile" :loading="pushLoading"
          >推 送</el-button
        >
      </span>
    </el-dialog>
    <!-- 资金转账弹窗 -->
    <el-dialog
      width="800px"
      title="资金转账"
      :visible.sync="fundsTransferDialog"
      append-to-body
      v-if="fundsTransferDialog"
      class="funds-transfer"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="转出账户">
          <el-input
            v-model="currentItem.CompanyName"
            style="width: 450px"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="可转余额">
          <el-input
            v-model="accountInfo.DealAmount"
            style="width: 450px"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="转入账户">
          <el-select
            v-model="selectedAccount"
            placeholder="请选择转入账户"
            style="width: 450px"
          >
            <el-option
              v-for="item in accountList"
              :key="item.UserID"
              :label="item.EnterpriseName"
              :value="item.UserID"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="转账金额">
          <el-input
            v-model="transferMoney"
            placeholder="请输入转账金额"
            style="width: 450px"
            @input="(e) => (transferMoney = getInputPrice(transferMoney))"
          ></el-input>
        </el-form-item>
        <el-form-item label="转账备注">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入备注"
            v-model="transferRemark"
            style="width: 450px"
            maxlength="50"
            show-word-limit
          >
          </el-input>
        </el-form-item>
        <el-form-item label="验证码">
          <el-input
            v-model="transferCode"
            placeholder="请输入验证码"
            style="width: 260px"
          ></el-input>
          <el-button type="primary" style="width:180px;margin-left: 10px;" @click="getVerifyCode" :disabled="!verifyCooling">
              {{
                verifyCoolingTime == 0 || verifyCoolingTime == 60
                ? "发送验证码"
                : `${verifyCoolingTime}秒重新获取`
              }}
            </el-button>
        </el-form-item>
        <el-form-item label="经办人">
          {{cfoInfo.Name}}-{{cfoInfo.Phone}}
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div
          style="
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div style="font-size: 16px; font-weight: bold; color: red">
            累计转出金额：{{ totalMoney | formatMoney }}
          </div>
          <div>
            <el-button type="primary" @click="toClearRecord()">清 除</el-button>
            <el-button @click="fundsTransferDialog = false">取 消</el-button>
            <el-button type="primary" @click="submitTransfer">转 账</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getAccountList,
  updatePublicQuery,
  getYMAccount,
  PushEnterpriseFile,
  GetBankUserPhone,
} from "@/api/finance/bank/index";
import {
  ClearRecord,
  AddRecord,
  GetEnterprises,
  TotalRecord,
  ExistPower,
  GetCFOInfo,
  SendVerifyCode
} from "@/api/TransferRecord/index";
import { inputPrice } from "@/utils/utils";
export default {
  data() {
    return {
      getInputPrice(str) {
        return inputPrice(str);
      },
      flag: {
        isRecord: false,
      },
      recordData: [],
      recordLoading: false,
      firmData: "",
      recordPagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      loading: false,
      //当前激活哪个tabs页
      activePage: "3", //2-货主，3-服务商，4-代理，5-司机
      // 表格数据
      tableData: [],
      // 分页数据
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      searchForm: {
        name: "", //姓名
        idcard: "", //身份证号
        phone: "", //手机号
        companyName: "", //企业名称
        authStatus: "", // 企业认证状态
      },
      pushFileDialog: false,
      pushFileTypeList: [
        {
          type: "平台合作协议",
          id: 50,
        },
        {
          type: "账户信息证明",
          id: 60,
        },
      ],
      pushFileType: null,
      currentUserId: "",
      pushLoading: false,
      fundsTransferDialog: false, // 资金转账弹窗
      accountList: [], // 转入账户列表
      selectedAccount: "", // 选中的账户
      transferMoney: "", // 转账金额
      transferRemark: "", // 转账备注
      transferCode:"",//转账验证码
      totalMoney: "", // 累计转账金额
      currentItem: {},
      accountInfo: {}, // 转账资金信息
      verifyCooling: true, //是否能按下发送验证码按钮
      verifyCoolingTime: 60, //剩下多少秒才能发送验证码
      cfoInfo:{},//经办人信息
    };
  },
  methods: {
    toFundsTransfer(item) {
      this.currentItem = item;
      this.totalMoney = "";
      this.transferRemark = "";
      this.transferMoney = "";
      this.selectedAccount = "";
      ExistPower().then((res) => {
        if (res.data) {
          this.getEnterprises();
          this.getTotalRecord();
          this.getYMAccount();
          this.fundsTransferDialog = true;
        } else {
          this.$message.error("抱歉，您没有转账权限！");
        }
      });
    },
    // 查询账户信息
    async getYMAccount() {
      await getYMAccount({
        userid: this.currentItem.UserId,
      }).then((res) => {
        if (res.data) {
          this.accountInfo = res.data || {};
        }
      });
    },
    // 获取转入账户列表
    async getEnterprises() {
      await GetEnterprises({}).then((res) => {
        this.accountList = res.data || [];
      });
    },
    // 获取转账记录统计
    async getTotalRecord() {
      await TotalRecord({
        srcUserID: this.currentItem.UserId,
      }).then((res) => {
        this.totalMoney = res.data.Amount || "";
      });
    },
    // 清除转账记录
    toClearRecord() {
      this.$confirm("确定要清除转账记录吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: "请求中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        ClearRecord({
          userId: this.currentItem.UserId,
        })
          .then(() => {
            this.$message.success("清除成功!");
            this.getTotalRecord();
          })
          .finally(() => {
            loading.close();
          });
      });
    },
    // 提交转账记录
    submitTransfer() {
      if (!this.selectedAccount) {
        this.$message.warning("请选择转入账户！");
        return;
      }
      if (!this.transferMoney) {
        this.$message.warning("请输入转账金额！");
        return;
      }
      if (Number(this.transferMoney) > Number(this.accountInfo.DealAmount)) {
        this.$message.warning("转账金额不能大于可转余额！");
        return;
      }
      if (!this.transferRemark) {
        this.$message.warning("请输入转账备注！");
        return;
      }
       if (!this.transferCode) {
        this.$message.warning("请输入验证码！");
        return;
      }
      this.$confirm("确定要转账吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: "请求中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        AddRecord({
          srcUserID: this.currentItem.UserId,
          targetUserID: this.selectedAccount,
          amount: this.transferMoney,
          remark: this.transferRemark,
          VerifyCode:this.transferCode,
        })
          .then(() => {
            this.$message.success("转账成功!");
            this.fundsTransferDialog = false;
            this.getAccountList();
          })
          .finally(() => {
            loading.close();
          });
      });
    },
    financeDetail(item) {
      this.$router.push({
        path: "/finance/bank/index",
        query: {
          UserId: item.UserId,
        },
      });
    },
    recordCurrentChange(e) {
      this.recordPagination.page = e;
      this.recordLoading = true;
      this.recordClick(this.firmData);
    },
    // 查询变更记录
    recordClick(item) {
      this.loading = true;
      this.firmData = item;
      let data = {
        UserId: item.UserId,
        pageSize: this.recordPagination.pagesize,
        pageIndex: this.recordPagination.page,
      };
      updatePublicQuery(data)
        .then((res) => {
          this.recordData = res.data.data.DataList;
          this.recordPagination.total = Number(res.data.data.TotalCount);
          this.flag.isRecord = true;
        })
        .finally(() => {
          if (this.recordPagination.page != 1) {
            this.recordLoading = false;
          } else {
            this.loading = false;
          }
        });
    },
    //搜索
    search() {
      this.pagination.page = 1;
      this.getAccountList();
    },
    // 获取账户信息列表
    getAccountList() {
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        identityID: this.activePage,
        name: this.searchForm.name,
        idcard: this.searchForm.idcard,
        phone: this.searchForm.phone,
        companyName: this.searchForm.companyName,
        authStatus: this.searchForm.authStatus,
      };
      this.loading = true;
      getAccountList(params)
        .then((res) => {
          this.tableData = res.data.DataList;
          this.loading = false;
          this.pagination.total = Number(res.data.TotalCount);
        })
        .finally(() => {
          this.loading = false;
          for (let i = 0; i < this.tableData.length; i++) {
            let obj = this.tableData[i];
            GetBankUserPhone({
              userid: obj.UserId,
            }).then((ress) => {
              if (ress.data) {
                this.$set(obj, "Phone", ress.data.TelPhone);
              }
            });
            getYMAccount({
              userid: obj.UserId,
            }).then((ress) => {
              if (ress.data) {
                this.$set(obj, "Balance", ress.data.ActBalance);
              }
            });
          }
        });
    },
    // tab栏点击事件
    handleClick(tab) {
      this.activePage = tab.name;
      this.$refs["searchForm"].resetFields();
      this.getAccountList();
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true;
      this.pagination.page = e;
      this.getAccountList();
    },
    // 推送文件
    toPushFile(userid) {
      this.currentUserId = userid;
      this.pushFileType = null;
      this.pushFileDialog = true;
    },
    submitPushFile() {
      if (!this.pushFileType) {
        this.$message.warning("请选择需要推送的文件类型！");
        return;
      }
      this.$confirm("确定推送此文件吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.pushLoading = true;
          PushEnterpriseFile({
            userId: this.currentUserId,
            fileType: this.pushFileType,
          })
            .then((res) => {
              this.$message.success("推送成功");
              this.pushFileDialog = false;
            })
            .finally(() => {
              this.pushLoading = false;
            });
        })
        .catch(() => {});
    },

    //发送验证码
    getVerifyCode(){
       //正在倒计时中直接return
       if (!this.verifyCooling) return;
       this.verifyCooling = false;
      this.verifyCoolingTime = this.verifyCoolingTime - 1;
      //倒计时60S才能再次发送验证码
      let timer = setInterval(() => {
        this.verifyCoolingTime = this.verifyCoolingTime - 1;
        if (this.verifyCoolingTime === 0) {
          this.verifyCooling = true;
          this.verifyCoolingTime = 60;
          clearInterval(timer);
        }
      }, 1000);

      SendVerifyCode().then((res)=>{
        if (res.code === 0) {
          this.$message.success("验证码发送成功");
        }
      })
    }
  },
  created() {
    this.getAccountList();

    GetCFOInfo().then((res)=>{
      this.cfoInfo=res.data;
    })
  },
};
</script>

<style lang="scss" scope>
.bankWraps {
  .recordDialog {
    .el-dialog__body {
      padding: 0 20px 30px 20px;
    }
  }
}
</style>
