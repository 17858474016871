import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

// 转账记录分页列表
export const PageRecord = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/TransferRecord/PageRecord?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取转入账户集合
export const GetEnterprises = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/TransferRecord/GetEnterprises`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 新增转账记录
export const AddRecord = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/TransferRecord/AddRecord`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 清除转账记录
export const ClearRecord = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/TransferRecord/ClearRecord`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 转账记录统计
export const TotalRecord = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/TransferRecord/TotalRecord`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 校验用户是否有转账权限
export const ExistPower = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/TransferRecord/ExistPower`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取经办人信息
export const GetCFOInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/TransferRecord/CFOInfo`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 发送验证码
export const SendVerifyCode = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/TransferRecord/SendVerifyCode`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}